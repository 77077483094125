import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/dashboard.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report',
      name: 'report',
      component: () => import('@/views/report/report.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
        pageTitle: 'Report',
        breadcrumb: [
          {
            text: 'W/L Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/agent/agent-list',
      name: 'agent-list',
      component: () => import('@/views/agent/agentList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
        pageTitle: 'Member & Agents',
        breadcrumb: [
          {
            text: 'Agents List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/agent/create-agent',
      name: 'create-agent',
      component: () => import('@/views/agent/addAgent.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
        pageTitle: 'Member & Agents',
        breadcrumb: [
          {
            text: 'Create agent',
            active: true,
          },
        ],
      },
    },
    {
      path: '/agent/edit-agent',
      name: 'edit-agent',
      component: () => import('@/views/agent/editAgent.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
        pageTitle: 'Member & Agents',
        breadcrumb: [
          {
            text: 'Edit agent',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next()
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
